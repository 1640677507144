var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        {
          staticClass: "pt-0 page-background mainArea",
          attrs: { fluid: "", tag: "section" },
        },
        [
          _c("TopBar", { attrs: { title: "Update ObjectIDs" } }),
          _c(
            "div",
            {
              staticClass:
                "page-background d-flex flex-column align-center px-1",
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "my-3",
                  attrs: {
                    width: _vm.$vuetify.breakpoint.xsOnly ? "100%" : "70%",
                  },
                },
                [
                  _c("v-card-title", { staticClass: "text-h5 d-flex gap" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "circle d-flex justify-center align-center",
                      },
                      [_vm._v("1")]
                    ),
                    _vm._v(" Export Custom Template "),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("validation-observer", {
                        ref: "exportCustomTemplateForm",
                        attrs: { slim: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ valid }) {
                              return [
                                _c("p", { staticClass: "text-caption" }, [
                                  _vm._v(
                                    " Select a feature service layer that needs its ObjectIDs updated. "
                                  ),
                                ]),
                                _c("validation-provider", {
                                  attrs: { name: "Layer", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function ({ errors, valid }) {
                                          return [
                                            _c("v-autocomplete", {
                                              attrs: {
                                                label: "Layer",
                                                items: _vm.mapServiceChoices,
                                                "item-text": "label",
                                                "item-value": "value",
                                                "error-messages": errors,
                                                success: valid,
                                                color: "primary",
                                                "hide-details": "auto",
                                              },
                                              on: { change: _vm.getGisFields },
                                              model: {
                                                value: _vm.mapServiceId,
                                                callback: function ($$v) {
                                                  _vm.mapServiceId = $$v
                                                },
                                                expression: "mapServiceId",
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c("p", { staticClass: "text-caption" }, [
                                  _vm._v(
                                    " Optionally, you can add a date range for updating features with data from a specific time period. "
                                  ),
                                ]),
                                _c(
                                  "section",
                                  {
                                    staticClass:
                                      "d-flex align-center justify-space-between flex-wrap my-2 gap",
                                  },
                                  [
                                    _c("validation-provider", {
                                      staticClass: "flex-grow-1",
                                      attrs: { name: "fromDate" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c(
                                                  "v-menu",
                                                  {
                                                    ref: "fromDateMenu",
                                                    attrs: {
                                                      "offset-y": "",
                                                      "min-width": "auto",
                                                      "close-on-content-click": false,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-text-field",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "From Date",
                                                                      color:
                                                                        "primary",
                                                                      "error-messages":
                                                                        errors,
                                                                      success:
                                                                        valid,
                                                                      "hide-details":
                                                                        "auto",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.fromDate,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.fromDate =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "fromDate",
                                                                    },
                                                                  },
                                                                  on
                                                                )
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("v-date-picker", {
                                                      on: {
                                                        "click:date": function (
                                                          $event
                                                        ) {
                                                          return _vm.$refs.fromDateMenu.save()
                                                        },
                                                      },
                                                      model: {
                                                        value: _vm.fromDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.fromDate = $$v
                                                        },
                                                        expression: "fromDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _c("validation-provider", {
                                      staticClass: "flex-grow-1",
                                      attrs: {
                                        name: "toDate",
                                        rules: {
                                          dateSameOrAfter: "@fromDate",
                                          required: Boolean(_vm.fromDate),
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors, valid }) {
                                              return [
                                                _c(
                                                  "v-menu",
                                                  {
                                                    ref: "toDateMenu",
                                                    attrs: {
                                                      "offset-y": "",
                                                      "min-width": "auto",
                                                      "close-on-content-click": false,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-text-field",
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      label:
                                                                        "To Date",
                                                                      color:
                                                                        "primary",
                                                                      "error-messages":
                                                                        errors,
                                                                      success:
                                                                        valid,
                                                                      "hide-details":
                                                                        "auto",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.toDate,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.toDate =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "toDate",
                                                                    },
                                                                  },
                                                                  on
                                                                )
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("v-date-picker", {
                                                      on: {
                                                        "click:date": function (
                                                          $event
                                                        ) {
                                                          return _vm.$refs.toDateMenu.save()
                                                        },
                                                      },
                                                      model: {
                                                        value: _vm.toDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.toDate = $$v
                                                        },
                                                        expression: "toDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "section",
                                  { staticClass: "d-flex justify-end" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { disabled: !valid },
                                        on: { click: _vm.exportObjectIdsCsv },
                                      },
                                      [
                                        _c("v-icon", { staticClass: "mr-1" }, [
                                          _vm._v(_vm._s(_vm.mdiExport)),
                                        ]),
                                        _vm._v(" Export ObjectIDs "),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card",
                {
                  staticClass: "my-3 gap",
                  attrs: {
                    width: _vm.$vuetify.breakpoint.xsOnly ? "100%" : "70%",
                  },
                },
                [
                  _c("v-card-title", { staticClass: "text-h5 d-flex gap" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "circle d-flex justify-center align-center",
                      },
                      [_vm._v("2")]
                    ),
                    _vm._v(" Add New ObjectID Values "),
                  ]),
                  _c("v-card-text", [
                    _c("p", { staticClass: "text-caption" }, [
                      _vm._v(
                        " Add a new ObjectID value for each old ObjectID. You can use the old ObjectID and GIS fields to identify what the new ObjectID is. "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "v-card",
                {
                  staticClass: "my-3 gap",
                  attrs: {
                    width: _vm.$vuetify.breakpoint.xsOnly ? "100%" : "70%",
                  },
                },
                [
                  _c("v-card-title", { staticClass: "text-h5 d-flex gap" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "circle d-flex justify-center align-center",
                      },
                      [_vm._v("3")]
                    ),
                    _vm._v(" Import and Update ObjectIDs "),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("p", { staticClass: "text-caption" }, [
                        _vm._v(
                          " Import ObjectIDs from step 2 to update. Be sure to include a new ObjectID for each old ObjectID in the file. "
                        ),
                      ]),
                      _c("file-pond", {
                        ref: "file",
                        attrs: {
                          name: "file",
                          "label-idle": "Tap or drop files here...",
                          "allow-multiple": false,
                        },
                        on: {
                          addfile: _vm.onAddFile,
                          removefile: _vm.onRemoveFile,
                        },
                      }),
                      _vm.objectIdFile
                        ? _c("section", { staticClass: "text-caption" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.numRowsToExport) +
                                " Rows of Data Loaded "
                            ),
                          ])
                        : _vm._e(),
                      _vm.showUploadSuccessMessage
                        ? _c("section", { staticClass: "text-caption" }, [
                            _vm._v(" ObjectIDs updated successfully "),
                          ])
                        : _vm._e(),
                      _vm.showUploadFailureMessage
                        ? _c("section", { staticClass: "text-caption" }, [
                            _vm._v(
                              " There is an error updating ObjectIDs, please try again. "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "section",
                        { staticClass: "d-flex justify-end" },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { disabled: !_vm.objectIdFile },
                              on: { click: _vm.updateObjectIds },
                            },
                            [
                              _c("v-icon", { staticClass: "mr-1" }, [
                                _vm._v(_vm._s(_vm.mdiSendVariant)),
                              ]),
                              _vm._v(" Update ObjectIDs "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }